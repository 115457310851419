import { isExperimentEnabled } from './experiments';
import {
  BOOKINGS_DEF_ID,
  MEMBERS_AREA_DEF_ID,
  EXPERIMENT_NEW_MEMBERS_AREA_INSTALL,
} from '../constants';

export async function installMembersArea(sdk, appToken) {
  sdk.application.install(appToken, {
    appDefinitionId: MEMBERS_AREA_DEF_ID,
    initiatorAppDefinitionId: BOOKINGS_DEF_ID
  })
}

export async function addBookingsMembersArea(sdk, appToken) {
  const membersAPI = sdk.application.getPublicAPI(appToken, {appDefinitionId: MEMBERS_AREA_DEF_ID});
  const isNewInstallation = await isExperimentEnabled(EXPERIMENT_NEW_MEMBERS_AREA_INSTALL);

  if (isNewInstallation) {
    return membersAPI.addApplications([
      {
        appDefinitionId: BOOKINGS_DEF_ID,
        pageId: 'bookings_member_area',
        social: false,
        showInLoginMenu: true,
        method: 'addSection',
      },
    ]);
  }

  return membersAPI.addSection({
    appDefinitionId: BOOKINGS_DEF_ID,
    pageId: 'bookings_member_area',
    social: false,
    showInLoginMenu: true
  });
}
