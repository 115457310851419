export const MEMBERS_AREA_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const BOOKINGS_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';
export enum WidgetsId {
  BOOKINGS_LIST = '621bc837-5943-4c76-a7ce-a0e38185301f',
}

export enum PageId {
  BOOKINGS_LIST = 'bookings_list',
  BOOKINGS_CHECKOUT = 'book_checkout',
}

export const EXPERIMENT_NEW_MEMBERS_AREA_INSTALL = 'specs.bookingsViewerScript.MembersAreaInstallV2';

export const PETRI_BASE_URL = 'https://www.wix.com';
export const PETRI_SCOPE = 'bookings-viewer-script';
